const SEO = [
	{
		page: "home",
		description:
			"Software Engineer | Self-taught Illustrator | Philosophy Junkie | Pumpkin Spice Elf",
		keywords: ["Bangy", "Studios", "BangyStudios", "BangyDraws", "BangyArt", "ICBMedia"],
	},

	{
		page: "about",
		description:
			"Software Engineer | Self-taught Illustrator | Philosophy Junkie | Pumpkin Spice Elf",
		keywords: ["Bangy", "Studios", "BangyStudios", "BangyDraws", "BangyArt", "ICBMedia"],
	},

	{
		page: "articles",
		description:
			"Software Engineer | Self-taught Illustrator | Philosophy Junkie | Pumpkin Spice Elf",
		keywords: ["Bangy", "Studios", "BangyStudios", "BangyDraws", "BangyArt", "ICBMedia"],
	},

	{
		page: "projects",
		description:
			"Software Engineer | Self-taught Illustrator | Philosophy Junkie | Pumpkin Spice Elf",
		keywords: ["Bangy", "Studios", "BangyStudios", "BangyDraws", "BangyArt", "ICBMedia"],
	},

	{
		page: "contact",
		description:
			"Software Engineer | Self-taught Illustrator | Philosophy Junkie | Pumpkin Spice Elf",
		keywords: ["Bangy", "Studios", "BangyStudios", "BangyDraws", "BangyArt", "ICBMedia"],
	},
];

export default SEO;
